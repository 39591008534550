import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Icon from "../../lt-dashboard/components/Icon";
import ExportedImage from "next-image-export-optimizer";
import Button from "../../lt-dashboard/components/Button";
import InputField from "../../lt-dashboard/components/InputField";
import { setDotFunc, setCreditFunc } from "../../lt-dashboard/Redux/Actions/loadingAction";

export default function Home() {
  const router = useRouter();
  const dispatch = useDispatch();

  //Local storage
  const token = localStorage.getItem("token");
  const LTtoken = localStorage.getItem("LTtoken");
  const mainToken = localStorage.getItem("mainToken");
  const isMobile = useMediaQuery({ maxWidth: "850px" });
  const DispatcherToken = localStorage.getItem("DispatcherToken");

  const [isMob, setIsMobile] = useState(null);
  const [dot, setDot] = useState({ value: "", error: false, message: "" });

  useEffect(() => {
    dispatch(setDotFunc(""));
    localStorage.removeItem("withOutDot");
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") setIsMobile(isMobile);
  }, [isMobile]);

  const loadRoutes = () => {
    if (mainToken || LTtoken || DispatcherToken) {
      return router.replace(mainToken ? "/Dashboard" : LTtoken ? "/LTteam/LTHome" : "/Dispatcher/Home");
    } else {
      return router.replace(`/Auth/Signup`);
    }
  };

  const loadRoutesForDot = () => {
    if (dot.value === "") {
      setDot({ ...dot, error: true, message: "DOT can't be empty" });
      return;
    } else {
      loadRoutes();
    }
  };

  const onContinueWithOutDot = () => {
    localStorage.setItem("withOutDot", true);
    router.push("/Auth/Signup");
  };

  return (
    <>
      <div className="testGrid mainPadding">
        <div className="flex xs:flex-col justify-center items-center mt-14">
          {/* <img src="/Images/bigTruck.webp" className="xs:py-5" width="700" /> */}
          <ExportedImage
            src="/Images/bigTruck.webp"
            alt="trucking insurance"
            decoding="async"
            width={"750"}
            height={"600"}
          />
          {/* For mobile view only */}
          <div className="xs:visible md:hidden custom:hidden flex flex-col items-center gap-10">
            <Button title="GET STARTED" onButtonClick={loadRoutes} />
          </div>
        </div>

        <div className="">
          <div className="flex flex-col  space-y-5">
            <h1 className="font-SpaceBold  text-landingText custom3:text-6xl custom4:text-6xl mf:text-5xl custom:text-3xl xs:text-3xl text-5xl">
              The easiest way to buy and manage commercial trucking insurance
            </h1>
            <h1 className="font-interMedium xs:text-lg text-xl text-landingText opacity-60">
              Get personalized quotes and manage your trucking insurance needs with LuckyTruck.
            </h1>
          </div>

          {!mainToken && !LTtoken && !DispatcherToken && !token && (
            <>
              <div className="flex xs:flex-col xs:gap-y-3 flex-row   gap-x-8 mt-10 mb-5">
                <InputField
                  width={"w-full"}
                  isDot={true}
                  error={dot.error}
                  type={"number"}
                  value={dot.value}
                  onInputChange={(e) => {
                    setDot({
                      ...dot,
                      error: false,
                      message: "",
                      value: e.target.value,
                    });
                    dispatch(setDotFunc(e.target.value));
                  }}
                  placeholder={"Enter DOT"}
                />

                <Button title="LET'S GO" onButtonClick={loadRoutesForDot} additionStyle={"px-10"} />
              </div>
              {dot.error ? <h5 className="errorMessage xs:text-sm -mt-3">{dot.message}</h5> : null}
              <div
                onClick={onContinueWithOutDot}
                className=" underline text-newStatus  font-hkMedium xs:text-sm custom:text-sm sm:text-sm  md:text-lg lg:text-xl cursor-pointer "
              >
                No, DOT? You can still get a quote! Let’s go!
              </div>
            </>
          )}

          {/* <div className="xs:hidden flex justify-center items-center mt-10">
            <Button title="GET STARTED" onButtonClick={loadRoutes} />
          </div> */}
        </div>
      </div>

      <div className="testGrid mainPadding items-center">
        <div className="flex justify-center items-center">
          {/* <img src="/Images/landing2.webp" width="700" /> */}
          <ExportedImage
            src="/Images/Home/landing2.webp"
            alt="trucking insurance 1"
            decoding="async"
            width={"700"}
            height={"600"}
          />
        </div>
        <div className=" xs:row-start-1">
          <div className="flex flex-col space-y-5" data-aos="fade-up">
            <h3 className="font-SpaceBold text-5xl text-landingText mf:text-4xl custom:text-4xl xs:text-3xl">
              Sign in & create your account in 45 seconds
            </h3>
            <h3 className="font-interMedium text-xl text-landingText opacity-60">
              Just tell us your DOT, and LuckyTruck will get to work as your trusted truck insurance provider. Creating
              a profile with LuckyTruck is simple, and rewarding.
            </h3>
            <div className="flex flex-row space-x-3">
              <Icon name="icon-landingTick" />
              <h3>Secure for your company</h3>
            </div>
            <div className="flex flex-row space-x-3">
              <Icon name="icon-landingTick" />
              <h3>Easy to use</h3>
            </div>
            <div className="flex flex-row space-x-3">
              <Icon name="icon-landingTick" />
              <h3>Accessible from anywhere</h3>
            </div>
          </div>
        </div>
      </div>

      {process.env.NEXT_PUBLIC_NODE_ENV === "staging" && (
        <div
          className="testGrid mainPadding"
          data-aos="fade-up"
          data-aos-offset="20"
          data-aos-duration="260"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <div className="flex flex-col space-y-5">
            <h3 className="font-interMedium text-xl text-landingText opacity-60 xs:text-lg">
              Need Help Financing Your Insurance?
            </h3>
            <h3 className="font-SpaceBold text-5xl text-landingText custom:text-4xl mf:text-4xl xs:text-3xl">
              LuckyTruck Credit Card
            </h3>
            <h3 className="font-interMedium text-xl xs:text-base text-landingText opacity-60">
              The LuckyTruck Credit Card is geared specifically for small trucking companies and their fleet managers
              with a sign-up bonus, up to 3% cash back, and other benefits to support trucking businesses.
            </h3>
            <h3 className="font-SpaceBold text-xl xs:text-lg text-landingText">
              Apply for a Card or Manage for Current Cardholders
            </h3>
            <Button
              title="GET HELP PAYING FOR YOUR INSURANCE"
              onButtonClick={() => {
                dispatch(setCreditFunc(true));
                router.push("/Auth/Signup");
              }}
            />
          </div>
          <div className="flex justify-center items-center">
            <ExportedImage
              src="/Images/cardMan.png"
              alt="trucking insurance 2"
              decoding="async"
              width={"550"}
              height={"350"}
            />
          </div>
        </div>
      )}

      <div
        className="testGrid mainPadding"
        data-aos="fade-up"
        data-aos-offset="20"
        data-aos-duration="260"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-anchor-placement="top-center"
      >
        <div className="">
          <div className="flex flex-col space-y-5">
            <h3 className="font-SpaceBold text-5xl text-landingText custom:text-4xl mf:text-4xl xs:text-3xl">
              Best-In-Class Customer Success Team
            </h3>
            <h3 className="font-interMedium text-xl text-landingText opacity-60">
              Whether it is your first time calling or you’ve driven a million miles with LuckyTruck in your passenger
              seat, our top-notch team is here to help.
            </h3>
            <div className="xs:hidden">
              <ExportedImage
                src="/Images/Home/landing4.webp"
                alt="trucking insurance 3"
                decoding="async"
                width={"350"}
                height={"60"}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <ExportedImage
            src="/Images/Home/landing3.webp"
            alt="trucking insurance 4"
            decoding="async"
            width={"650"}
            height={"600"}
          />
        </div>
      </div>

      <div className="testGrid mainPadding py-10 items-center  bg-gray4">
        <div
          className=" flex justify-center items-center"
          data-aos="fade-left"
          data-aos-offset="20"
          data-aos-duration="260"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <ExportedImage
            src="/Images/Home/landing5.webp"
            alt="trucking insurance 5"
            decoding="async"
            width={"450"}
            height={"450"}
          />
        </div>
        <div
          className=""
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-duration="260"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <div className="flex flex-col  space-y-10 px-20 custom:p-10 mf:p-10 xs:px-10 xs:items-center">
            <h3 className="font-SpaceBold text-4xl text-white xs:text-3xl">Dynamic management at your fingertips</h3>
            <h3 className="font-interMedium text-xl text-white xs:text-base">
              Increase, decrease, and dynamically manage your insurance coverages in your LuckyTruck dashboard. No more
              truck insurance providers with pushy salespeople or pesky emails – keeping more money in your pocket.
            </h3>
            <Button title="JOIN TODAY" width={"w-60 custom:w-48"} onButtonClick={loadRoutes} />
          </div>
        </div>
      </div>

      <div className="py-5">
        <div className="flex py-20 text-center xs:px-1.5 justify-center custom:px-5 mf:px-0">
          <div className="w-1/2 custom:w-full xs:w-full mf:w-3/4">
            <h3 className="font-SpaceBold text-4xl text-landingText">Trusted by 700+ Commercial Trucking Companies</h3>
            <h3 className="font-interMedium text-xl text-landingText opacity-60 py-2">
              We are for the truckers, their livelihoods, and the companies that forge the way. Here are just a few
              reviews from truckers we ride shotgun with.
            </h3>
          </div>
        </div>

        <div className="">
          <div className="flex xs:flex-col custom:flex-col flex-row gap-10 px-20 xs:px-10 text-center">
            <div
              className=" bg-white shadow-lg p-6 xs:w-full custom:w-full tab-item  rounded-md flex flex-col items-center"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="400"
              data-aos-easing="ease-in-out-cubic"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <ExportedImage
                src="/Images/Home/content.webp"
                alt="trucking insurance 6"
                decoding="async"
                width={"70"}
                height={"70"}
              />
              <h3 className="text-landingText text-base font-hkMedium mt-8">
                “Everyone that I have encountered has been really helpful and has gone above and beyond.”
              </h3>
            </div>
            <div
              className="bg-white shadow-lg p-6 rounded-md flex flex-col items-center xs:w-full custom:w-full tab-item"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="400"
              data-aos-easing="ease-in-out-cubic"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <ExportedImage
                src="/Images/Home/client3.webp"
                alt="trucking insurance 7"
                width={"70"}
                decoding="async"
                height={"70"}
              />
              <h3 className=" text-landingText text-base font-hkMedium mt-8">
                ”Excellent help & always returned my calls or left messages. Thanks so much to Sarah for her help and
                expertise.”
              </h3>
            </div>
            <div
              className="bg-white shadow-lg p-6 rounded-md flex flex-col items-center xs:w-full custom:w-full tab-item"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="400"
              data-aos-easing="ease-in-out-cubic"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center"
            >
              <ExportedImage
                src="/Images/Home/client2.webp"
                alt="trucking insurance 8"
                decoding="async"
                width={"70"}
                height={"70"}
              />
              <h3 className=" text-landingText text-base font-hkMedium mt-8">
                “Corey was great! Service was fast and right to the point. Even got me a better quote from the company I
                got a quote from.”
              </h3>
            </div>
          </div>
        </div>

        <ExportedImage
          src="/Images/Home/bgTry.webp"
          alt="trucking insurance 9"
          decoding="async"
          width={"4000"}
          height={isMob ? "2500" : "800"}
        />
      </div>
    </>
  );
}
