import React from "react";
import Icon from "../Icon";

export default function InputField({
  min,
  max,
  name,
  error,
  width,
  height,
  isDot,
  iconName,
  iconStyle,
  onBlur,
  value = "",
  onIconClick,
  icon = false,
  label = false,
  type = "text",
  maxLength = null,
  onInputChange,
  disabled = false,
  additionStyle = "",
  placeholder = "Enter Value",
  message = "Error messages",
}) {
  const onInputChangeLocal = (e) => {
    if (type == "number" && max) {
      if (e.target.value < max + 1) {
        onInputChange(e);
      }
    } else {
      onInputChange(e);
    }
  };

  return (
    <div className={`flex flex-col gap-y-1 relative ${width} ${additionStyle}`}>
      {label && <label className="label">{label}</label>}
      <input
        min={min}
        max={max}
        name={name}
        type={type}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onInputChangeLocal}
        className={`${height}  ${error ? "errorInput" : "input"}`}
      />
      {icon && (
        <Icon
          label={label}
          name={iconName}
          onClick={onIconClick}
          additionStyle={iconStyle}
        />
      )}
      {error && (
        <>
          <Icon name="icon-warning" label={label} additionStyle={iconStyle} />
          {!isDot ? (
            <h5 className="errorMessage xs:text-sm">{message}</h5>
          ) : null}
        </>
      )}
    </div>
  );
}
