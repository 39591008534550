import React from "react";
import Head from "next/head";
import Home from "../components/LandingComps/Home";
import PageL1 from "../lt-dashboard/components/Layout2Page";

const LandingPage = () => {
  return (
    <PageL1
      title={
        process.env.NEXT_PUBLIC_THEME === "LT"
          ? "Trucking Insurance Provider | LuckyTruck"
          : "Trucking Insurance Provider | Flexport"
      }
    >
      <Head>
        <meta
          name="title"
          content="LuckyTruck | We're here to help! Buy commercial trucking insurance and manage it through one seamless platform. Get started now"
        />
        <meta
          name="keywords"
          content="trucking Insurance, truck insurance providers"
        />
        <meta
          name="description"
          content="Looking for a trucking insurance that won't break the bank? LuckyTruck has you covered! We offer affordable rates and comprehensive coverage, so you can rest assured that your truck is protected. Give us a call today to get started!"
        />
      </Head>

      <div className=" bg-gradient-to-b from-landing via-white to-white  overflow-hidden">
        <Home />
      </div>
    </PageL1>
  );
};

LandingPage.layout = "LL";
export default LandingPage;
